import React, { useState } from "react";
import axios from "axios";
import "./q.css";
export default function SendMessage() {
    const [text, setText] = useState("");

    const API_QUERY = "https://dusk.masliukov.com/v2-api/messages/query";
    const API_SEND_MESSAGE = "https://dusk.masliukov.com/v2-api/messages/send";
    const [query, setQuery] = useState("");
    const [usersFound, setUsersFound] = useState([]);
    const [queue, setQueue] = useState([]);

    const getUsersByQuery = async () => {
        const call = await axios.post(API_QUERY, { query: query });
        const { users } = call.data;
        setUsersFound(users);
    };
    const sendQueue = async () => {
        const isValidHTML = verifyHTMLString(text.trim());
        console.log({ isValidHTML });
        if (!isValidHTML) {
            return alert("Verify your HTML string, it's fucked");
        }

        const data = { message: text, query: query.trim() };
        // console.log(data);

        const call = await axios.post("https://dusk.masliukov.com/v2-api/messages/createQueueItem", data);
        // console.log(call);
        if (call.data.ok) {
            // setText("");
            loadQueue();
        }
    };

    const loadQueue = async () => {
        const request = await axios.post("https://dusk.masliukov.com/v2-api/messages/getQueue");
        // console.log(request.data.queue);
        setQueue(request.data.queue);
    };

    useState(() => {
        loadQueue();
    }, []);

    const verifyHTMLString = (htmlString) => {
        // Create a new DOMParser instance
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;

        // Serialize the parsed HTML back to a string
        const serializedHTML = tempDiv.innerHTML;

        // Compare the serialized HTML with the original HTML string
        // If they are not the same, the original HTML was not valid
        return serializedHTML === htmlString;
    };

    const deleteQueue = async (event) => {
        const id = event.currentTarget.dataset.idx;
        const request = await axios.post("https://dusk.masliukov.com/v2-api/messages/dropQueueItem", { id: id });
        if (request.data.ok) {
            loadQueue();
        }
    };

    return (
        <div className="content-block">
            <h1>Отправить сообщение</h1>
            <h2>Конструктор</h2>
            <h3>Группа пользователей </h3>
            <div className="get-users-group">
                <input
                    className="d-input"
                    placeholder="SELECT something FROM something WHERE something = something"
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    type="text"
                />
                <button className="d-button" onClick={getUsersByQuery}>
                    Load Query
                </button>
            </div>
            <h3>Найдено пользователей: {usersFound.length}</h3>
            {4096 - text.length} chars left
            <div className="send-message-group">
                <textarea
                    maxLength={4096}
                    className="d-input"
                    placeholder="Текст сообщения"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    type="text"
                />
                <button className="d-button mt24" onClick={sendQueue}>
                    Поставить в очередь
                </button>
            </div>
            <h2>Очередь сообщений</h2>
            <div className="queue-table">
                <div className="queue-item">
                    <div className="id">id</div>
                    <div className="ts">created_at</div>
                    <div className="message">message_text</div>
                    <div className="u">planned / delivered / failed</div>
                </div>
                {queue.map((item) => {
                    return (
                        <div className="queue-item">
                            <div className="id">{item.id}</div>
                            <div className="ts">{item.created_at}</div>
                            <div className="message">{item.message}</div>
                            <div className="u">
                                {item.users_length} / {item.successful} / {item.failed}
                            </div>
                            <button data-idx={item.id} onClick={deleteQueue} className="delete">
                                🗑️
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
